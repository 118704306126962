import {
  MenuModuleIDMap,
  PermissionByPage,
} from '@/core/interfaces/permission.interface';
import {
  COLLABORATOR_STATE,
  PRODUCT_STATE,
  REQUEST_STATE,
} from './state.constants';

/**
 * Mapeo de los permisos por modulo del menu.
 */
export const PERMISSION_BY_PAGE: PermissionByPage = {
  REQUEST: {
    REQUEST_LIST: '553-050',
    REQUEST_SHOW: '553-051',
    REQUEST_CREATE: '553-052',
    REQUEST_UPDATE: '553-053',
    REQUEST_DELETE: '553-054',
    REQUEST_REQUEST_EVALUATE: '553-055',
    REQUEST_CLONE: '553-056',
    PRODUCT_ADD: '553-058',
    PRODUCT_UPDATE: '553-059',
    PRODUCT_DROP: '553-060',
    PRODUCT_ATTACHMENT_SEE: '553-061',
    REQUEST_SEND_COLLABORATOR: '553-057',
    COLLABORATOR_ADD: '553-062',
    COLLABORATOR_DROP: '553-063',
    COLLABORATOR_ATTACHMENT_SEE: '553-064',
    BINNACLE_SEE: '553-065',
    COLLABORATOR_DELIVERABLE_SEE: '553-108',
  },
  PENDING_REQUEST: {
    PENDING_REQUEST_LIST: '565-070',
    REQUEST_SHOW: '565-071',
    REQUEST_EVALUATE: '565-072',
    REQUEST_SEND_PRODUCER: '565-073',
    REQUEST_SEND_COLLABORATOR: '565-074',
    PRODUCT_ADD: '565-075',
    PRODUCT_UPDATE: '565-076',
    PRODUCT_DROP: '565-077',
    PRODUCT_ASSIGN: '565-078',
    PRODUCT_EVALUATE: '565-079',
    PRODUCT_APPROVE: '565-080',
    PRODUCT_DECLINE: '565-081',
    PRODUCT_ATTACHMENT_SEE: '565-082',
    COLLABORATOR_ATTACHMENT_SEE: '565-083',
    COLLABORATOR_DELIVERABLE_REGISTER: '565-084',
    BINNACLE_SEE: '565-065',
    COLLABORATOR_DELIVERABLE_SEE: '565-108',
  },
  PENDING_PRODUCT: {
    PENDING_PRODUCT_LIST: '566-090',
    REQUEST_SHOW: '566-091',
    PRODUCT_ATTACHMENT_SEE: '566-092',
    COLLABORATOR_ATTACHMENT_SEE: '566-093',
    COLLABORATOR_DELIVERABLE_REGISTER: '566-094',
    BINNACLE_SEE: '566-065',
    COLLABORATOR_DELIVERABLE_SEE: '566-108',
  },
  PRODUCT: {
    PRODUCT_LIST: '555-100',
    REQUEST_SHOW: '555-101',
    PRODUCT_CANCEL: '555-102',
    PRODUCT_ATTACHMENT_SEE: '555-103',
    COLLABORATOR_REASIGN: '555-104',
    COLLABORATOR_CANCEL: '555-105',
    COLLABORATOR_DATEEND_CHANGE: '555-106',
    COLLABORATOR_ATTACHMENT_SEE: '555-107',
    COLLABORATOR_DELIVERABLE_SEE: '555-108',
    BINNACLE_SEE: '555-065',
  },
  MEDIA_PRODUCT: {
    MEDIA_PRODUCT_LIST: '561-110',
    MEDIA_CREATE: '561-111',
    MEDIA_UPDATE: '561-112',
    MEDIA_DELETE: '561-113',
    PRODUCT_CREATE: '561-114',
    PRODUCT_UPDATE: '561-115',
    PRODUCT_DELETE: '561-116',
  },
  CUSTOMER: {
    CUSTOMER_LIST: '556-120',
    CUSTOMER_CREATE: '556-121',
    CUSTOMER_UPDATE: '556-122',
    CUSTOMER_ACTIVE: '556-123',
    CUSTOMER_DEACTIVATE: '556-124',
  },
  USER: {
    USER_LIST: '559-130',
    USER_SHOW: '559-131',
    USER_CREATE: '559-132',
    USER_UPDATE: '559-133',
    USER_ACTIVE: '559-134',
    USER_DEACTIVATE: '559-135',
  },
  WORK_JOB: {
    WORK_JOB_LIST: '562-140',
    WORK_JOB_CREATE: '562-141',
    WORK_JOB_UPDATE: '562-142',
    WORK_JOB_DELETE: '562-143',
  },
  REQUEST_AREA: {
    REQUEST_AREA_LIST: '563-150',
    REQUEST_AREA_CREATE: '563-151',
    REQUEST_AREA_UPDATE: '563-152',
    REQUEST_AREA_DELETE: '563-153',
  },
  NO_WORKING_DAY: {
    NO_WORKING_DAY_LIST: '560-160',
    NO_WORKING_DAY_CREATE: '560-161',
    NO_WORKING_DAY_DELETE: '560-162',
  },
  DASHBOARD: {
    DASHBOARD_VIEW: '551-170',
    DASHBOARD_APPLY_FILTER: '551-171',
  },
  REPORT: {
    REPORT_VIEW: '552-180',
    DOWNLOAD_REPORT: '552-181',
  },
  AUDIT: {
    AUDIT_LIST: '557-190',
    AUDIT_SHOW: '557-191',
  },
  CONFIGURATION: {},
  PENDING: {},
};

/**
 * Mapeo de los codigos de los permisos de los modulos del menu.
 */
export const MENU_MODULE_ID_MAP: MenuModuleIDMap = {
  DASHBOARD: '551',
  REPORT: '552',
  REQUEST: '553',
  PENDING: '554',
  PRODUCT: '555',
  CUSTOMER: '556',
  AUDIT: '557',
  CONFIGURATION: '558',
  USER: '559',
  NO_WORKING_DAY: '560',
  MEDIA_PRODUCT: '561',
  WORK_JOB: '562',
  REQUEST_AREA: '563',
  PENDING_REQUEST: '565',
  PENDING_PRODUCT: '566',
};

/**
 * Mapeo de los modulos del Menu.
 */
export const MENU_MODULE = {
  DASHBOARD: 'DASHBOARD',
  REPORT: 'REPORT',
  REQUEST: 'REQUEST',
  PENDING: 'PENDING',
  PENDING_REQUEST: 'PENDING_REQUEST',
  PENDING_PRODUCT: 'PENDING_PRODUCT',
  PRODUCT: 'PRODUCT',
  MEDIA_PRODUCT: 'MEDIA_PRODUCT',
  CUSTOMER: 'CUSTOMER',
  CONFIGURATION: 'CONFIGURATION',
  USER: 'USER',
  WORK_JOB: 'WORK_JOB',
  REQUEST_AREA: 'REQUEST_AREA',
  NO_WORKING_DAY: 'NO_WORKING_DAY',
  AUDIT: 'AUDIT',
} as const;

/**
 * Mapeo de las acciones de las solicitudes.
 */
export const REQUEST_ACTION = {
  SHOW_REQUEST: 'SHOW_REQUEST',
  CREATE_REQUEST: 'CREATE_REQUEST',
  UPDATE_REQUEST: 'UPDATE_REQUEST',
  DELETE_REQUEST: 'DELETE_REQUEST',
  COPY_REQUEST: 'COPY_REQUEST',
  EVALUATE_REQUEST: 'EVALUATE_REQUEST', // Esto incluiria el boton de Ir a la pagina y los botones de Aprobar y Rechazar
  REQUEST_EVALUATE_REQUEST: 'REQUEST_EVALUATE_REQUEST',
  GO_TO_ADD_PRODUCT: 'GO_TO_ADD_PRODUCT',
  ADD_PRODUCT: 'ADD_PRODUCT',
  EDIT_PRODUCT: 'EDIT_PRODUCT',
  DELETE_PRODUCT: 'DELETE_PRODUCT',
  SEND_TO_COLLABORATORS: 'SEND_TO_COLLABORATORS',
  ADD_COLLABORATOR: 'ADD_COLLABORATOR',
  DELETE_COLLABORATOR: 'DELETE_COLLABORATOR',
  SEND_TO_PRODUCERS: 'SEND_TO_PRODUCERS',
  GO_TO_EVALUATE_PRODUCT: 'GO_TO_EVALUATE_PRODUCT', // Accion de lista de IR a la pagina de evaluar productos
  APPROVE_PRODUCT: 'APPROVE_PRODUCT',
  DECLINE_PRODUCT: 'DECLINE_PRODUCT',
  ADD_ATTACHMENT_COLLABORATOR: 'ADD_ATTACHMENT_COLLABORATOR', // Registrar entregable
  GO_TO_ASSIGN_PRODUCT: 'ASSIGN_PRODUCT',
};

/**
 * Mapeo de las acciones de los productos.
 */
export const PRODUCT_ACTION = {
  SEND_REVISION: 'SEND_REVISION_PRODUCT', // Esto sale en el modal de Registrar entregable
  CANCEL_PRODUCT: 'CANCEL_PRODUCT',
  SEE_ATTACHMENT_COLLABORATOR: 'SEE_ATTACHMENT_COLLABORATOR',
  SEE_ATTACHMENT_PRODUCT: 'SEE_ATTACHMENT_PRODUCT',
};

/**
 * Mapeo de las acciones de los colaboradores.
 */
export const COLLABORATOR_ACTION = {
  REGISTER_DELIVERABLE: 'REGISTER_DELIVERABLE',
  REASIGN_COLLABORATOR: 'REASIGN_COLLABORATOR',
  CHANGE_DATEEND_COLLABORATOR: 'CHANGE_DATEEND_COLLABORATOR',
  CANCEL_COLLABORATOR: 'CANCEL_COLLABORATOR',
  SEE_ATTACHMENT_COLLABORATOR: 'SEE_ATTACHMENT_COLLABORATOR',
  SEE_DELIVERABLE_COLLABORATOR: 'SEE_DELIVERABLE_COLLABORATOR',
};

/**
 * Mapeo de las acciones de las solicitudes por estado.
 */
export const REQUEST_ACTIONS_BY_STATE = {
  [REQUEST_STATE.REGISTERED]: [
    REQUEST_ACTION.SHOW_REQUEST,
    REQUEST_ACTION.UPDATE_REQUEST,
    REQUEST_ACTION.DELETE_REQUEST,
    REQUEST_ACTION.REQUEST_EVALUATE_REQUEST,
    REQUEST_ACTION.ADD_PRODUCT,
    REQUEST_ACTION.EDIT_PRODUCT,
    REQUEST_ACTION.DELETE_PRODUCT,
  ],
  [REQUEST_STATE.PENDING]: [
    REQUEST_ACTION.SHOW_REQUEST,
    REQUEST_ACTION.UPDATE_REQUEST,
    REQUEST_ACTION.EVALUATE_REQUEST,
    REQUEST_ACTION.ADD_PRODUCT,
    REQUEST_ACTION.EDIT_PRODUCT,
    REQUEST_ACTION.DELETE_PRODUCT,
  ],
  [REQUEST_STATE.REJECTED]: [
    REQUEST_ACTION.SHOW_REQUEST,
    REQUEST_ACTION.UPDATE_REQUEST,
    REQUEST_ACTION.REQUEST_EVALUATE_REQUEST,
    REQUEST_ACTION.ADD_PRODUCT,
    REQUEST_ACTION.EDIT_PRODUCT,
    REQUEST_ACTION.DELETE_PRODUCT,
  ],
  [REQUEST_STATE.APPROVED]: [
    REQUEST_ACTION.SHOW_REQUEST,
    REQUEST_ACTION.ADD_COLLABORATOR,
    REQUEST_ACTION.DELETE_COLLABORATOR,
    REQUEST_ACTION.GO_TO_ADD_PRODUCT,
    REQUEST_ACTION.SEND_TO_COLLABORATORS,
    REQUEST_ACTION.SEND_TO_PRODUCERS,
    REQUEST_ACTION.GO_TO_ASSIGN_PRODUCT,
    REQUEST_ACTION.ADD_PRODUCT,
    REQUEST_ACTION.DELETE_PRODUCT,
    REQUEST_ACTION.EDIT_PRODUCT,
  ],

  [REQUEST_STATE.MANAGED]: [
    REQUEST_ACTION.SHOW_REQUEST,
    REQUEST_ACTION.ADD_PRODUCT,
    REQUEST_ACTION.EDIT_PRODUCT,
    REQUEST_ACTION.DELETE_PRODUCT,
    REQUEST_ACTION.SEND_TO_COLLABORATORS,
    REQUEST_ACTION.GO_TO_ASSIGN_PRODUCT,
  ],
  [REQUEST_STATE.ASSIGNED]: [
    REQUEST_ACTION.SHOW_REQUEST,
    REQUEST_ACTION.GO_TO_EVALUATE_PRODUCT,
  ],
  [REQUEST_STATE.COMPLETED]: [
    REQUEST_ACTION.SHOW_REQUEST,
    REQUEST_ACTION.GO_TO_EVALUATE_PRODUCT,
  ],
};

/**
 * Mapeo de las acciones de los productos por estado.
 */
export const PRODUCT_ACTIONS_BY_STATE = {
  [PRODUCT_STATE.REGISTERED]: [PRODUCT_ACTION.CANCEL_PRODUCT],
  [PRODUCT_STATE.ASSIGNED]: [PRODUCT_ACTION.SEND_REVISION],
  [PRODUCT_STATE.FINISHED]: [],
  [PRODUCT_STATE.APPROVED]: [],
  [PRODUCT_STATE.OBSERVED]: [],
  [PRODUCT_STATE.CANCELED]: [],
};

/**
 * Mapeo de las acciones de los colaboradores por estado.
 */
export const COLLABORATOR_ACTIONS_BY_STATE = {
  [COLLABORATOR_STATE.REGISTERED]: [
    COLLABORATOR_ACTION.SEE_ATTACHMENT_COLLABORATOR,
  ],
  [COLLABORATOR_STATE.ASSIGNED]: [
    COLLABORATOR_ACTION.REASIGN_COLLABORATOR,
    COLLABORATOR_ACTION.CHANGE_DATEEND_COLLABORATOR,
    COLLABORATOR_ACTION.CANCEL_COLLABORATOR,
    COLLABORATOR_ACTION.REGISTER_DELIVERABLE,
    COLLABORATOR_ACTION.SEE_ATTACHMENT_COLLABORATOR,
  ],
  [COLLABORATOR_STATE.FINISHED]: [
    COLLABORATOR_ACTION.SEE_ATTACHMENT_COLLABORATOR,
  ],
  [COLLABORATOR_STATE.APPROVED]: [
    COLLABORATOR_ACTION.SEE_DELIVERABLE_COLLABORATOR,
  ],
  [COLLABORATOR_STATE.OBSERVED]: [
    COLLABORATOR_ACTION.REGISTER_DELIVERABLE,
    COLLABORATOR_ACTION.SEE_DELIVERABLE_COLLABORATOR,
  ],
  [COLLABORATOR_STATE.COMPLETED]: [
    COLLABORATOR_ACTION.SEE_DELIVERABLE_COLLABORATOR,
  ],
};
